import React from 'react';
import RichText from './RichText';
import ImageBlock from '../blocks/ImageBlock';
import Menu from './Menu';
import TextWithHeading from './TextWithHeading';
import ProjectTileBlock from '../blocks/ProjectTileBlock';

function PageBuilder(props) {
	const { type, pageBuilder, _rawPageBuilder } = props;

	// Load the right component, based on the _type from Sanity
	const Components = {
		richText: RichText,
		imageBlock: ImageBlock,
		navMenu: Menu,
		textWithHeading: TextWithHeading,
		projectTile: ProjectTileBlock,
	};

	// 'raw' content needs to be passed in for the PortableText Component
	return pageBuilder.map((block, index) => {
		if (Components[block._type]) {
			return React.createElement(Components[block._type], {
				key: block._key,
				block: block,
				type,
				raw: _rawPageBuilder[index],
			});
		}
	});
}

export default PageBuilder;
