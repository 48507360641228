import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Noise from '../components/Noise';

export default function BaseLayout({ children }) {
	return (
		<div className="relative min-h-screen overflow-hidden">
			<Header />
			<Noise />
			<div className="p-4 sm:p-8 mt-16 md:mt-0 md:ml-20 lg:p-16">
				{children}
				<Footer />
			</div>
		</div>
	);
}
