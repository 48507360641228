import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PageBuilder from './PageBuilder';
import icon from '../images/icon.svg';
import SanityLink from './SanityLink';

export default function Footer() {
	const data = useStaticQuery(graphql`
		query MyQuery {
			sanitySettingsFooter {
				acknowledgment
				_rawContent(resolveReferences: { maxDepth: 50 })
				legalMenu {
					items {
						_key
						_rawNavigationItemUrl
						text
						navigationItemUrl {
							_key
							blank
							href
							linkType
							internalLink {
								... on SanityArticle {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityArticleCategories {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityArticlesIndex {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityHomePage {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityLegalPage {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityPage {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityProject {
									id
									_type
									slug {
										current
									}
									title
								}
								... on SanityProjectCategories {
									id
									title
									_type
									slug {
										current
									}
								}
								... on SanityProjectsIndex {
									id
									title
									_type
									slug {
										current
									}
								}
							}
						}
					}
				}
				content {
					... on SanityNavMenu {
						...NavItems
					}
					... on SanityRichText {
						_key
						_type
						settings {
							desktopWidth
						}
					}
				}
			}
		}
	`);

	const footer = data.sanitySettingsFooter;
	return (
		<footer className="pt-4 lg:pt-8">
			<div className="border-black border-t py-4 sm:py-8 lg:py-16">
				<div className="grid grid-cols-12 gap-4 lg:gap-8">
					<PageBuilder
						pageBuilder={footer.content}
						_rawPageBuilder={footer._rawContent}
					/>
				</div>
			</div>
			<div className="border-black border-t py-4 sm:pt-8 lg:pt-16 grid grid-cols-1 md:grid-cols-4 gap-4 sm:gap-8">
				<div className="md:col-span-3">
					<div className="prose mb-4 lg:mb-8">
						<p>{footer.acknowledgment}</p>
					</div>

					{footer.legalMenu && (
						<ul className="flex flex-wrap">
							{footer.legalMenu.items.map((link) => (
								<li
									key={link._key}
									className="text-sm lg:text-base mr-4"
								>
									<SanityLink
										link={link.navigationItemUrl}
										className="opacity-100 hover:opacity-50 transition duration-200"
									>
										{link.text}
									</SanityLink>
								</li>
							))}
						</ul>
					)}
				</div>
				<div className="flex justify-start md:justify-end">
					<img
						className="w-24"
						src={icon}
						alt="E+F icon"
						width="px"
					/>
				</div>
			</div>
		</footer>
	);
}
