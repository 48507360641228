import React from 'react';
import SanityLink from '../SanityLink';
import FadeIn from '../FadeIn';

export default function Menu({ block }) {
	if (block.large === true) {
		return (
			<div
				className={`col-span-${
					block.settings?.mobileWidth || 12
				} lg:col-span-${block.settings?.desktopWidth || 12}`}
			>
				<FadeIn>
					<div className="flex flex-wrap">
						<ul className="flex flex-wrap">
							{block.heading && (
								<li className="mr-4">
									<h2>{block.heading}</h2>
								</li>
							)}
							{block.menu.items.map((link) => (
								<li key={link._key} className="h2 mb-0 mr-4">
									<SanityLink
										link={link.navigationItemUrl}
										className="opacity-50 hover:opacity-100 transition duration-200"
									>
										{link.text}
									</SanityLink>
								</li>
							))}
						</ul>
					</div>
				</FadeIn>
			</div>
		);
	} else {
		return (
			<div
				className={`col-span-${
					block.settings?.mobileWidth || 12
				} lg:col-span-${block.settings?.desktopWidth || 12}`}
			>
				<FadeIn>
					{block.heading && <h2 className="h3">{block.heading}</h2>}
					<ul>
						{block.menu.items.map((link) => (
							<li key={link._key}>
								<SanityLink
									link={link.navigationItemUrl}
									className="text-base lg:text-xl opacity-100 hover:opacity-50 transition duration-200"
									activeClassName="underline"
								>
									{link.text}
								</SanityLink>
							</li>
						))}
					</ul>
				</FadeIn>
			</div>
		);
	}
}
