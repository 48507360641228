import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function ProjectTile({ rel, block, project }) {
	var flip = '';
	var isRel = 'justify-between';
	if (rel?.flip === true) {
		flip = 'order-first lg:order-last';
	}
	if (rel != null) {
		isRel = '';
	}
	return (
		<div
			key={project.id}
			className="grid grid-cols-1 sm:grid-cols-3 gap-4 lg:gap-8 border-t border-black pt-4 lg:pt-8 "
		>
			<div className={flip + ` sm:col-span-2`}>
				<Link
					to={`/projects/` + project.slug.current}
					className={
						(`block aspect-landscape lg:` + rel?.aspectRatio ||
							`aspect-landscape`) +
						` group relative overflow-hidden bg-white`
					}
				>
					<div className="absolute inset-0 h-full w-full object-cover z-0">
						<GatsbyImage
							image={project.tileImage.asset.gatsbyImageData}
							alt={project.title + ' project image'}
							className="absolute inset-0 h-full w-full object-cover "
						/>
					</div>
					{project.tileHoverImage?.asset && (
						<div className="absolute inset-0 h-full w-full object-cover">
							<GatsbyImage
								image={
									project.tileHoverImage.asset.gatsbyImageData
								}
								alt={project.title + ' project image'}
								className="absolute inset-0 h-full w-full object-cover z-0 opacity-0 group-hover:opacity-100 transition duration-500"
							/>
						</div>
					)}
				</Link>
			</div>
			<div className={`flex flex-col ` + isRel}>
				<Link
					to={`/projects/` + project.slug.current}
					className="prose mb-4"
				>
					<h2 className="h3">{project.title}</h2>
				</Link>
				<span className="uppercase text-xs">
					{project.categories?.map((cat) => cat.title).join(', ')}
				</span>
			</div>
		</div>
	);
}
