exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-article-categories-js": () => import("./../../../src/templates/articleCategories.js" /* webpackChunkName: "component---src-templates-article-categories-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legalPage.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-categories-js": () => import("./../../../src/templates/projectCategories.js" /* webpackChunkName: "component---src-templates-project-categories-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

