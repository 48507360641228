import React from 'react';
import { PortableText } from '@portabletext/react';
import FadeIn from '../FadeIn';

const RichText = ({ block, raw, index }) => (
	<div
		className={`col-span-${block.settings?.mobileWidth || 12} lg:col-span-${
			block.settings?.desktopWidth || 12
		}`}
	>
		<FadeIn>
			<div className="prose">
				<PortableText value={raw.text} />
			</div>
		</FadeIn>
	</div>
);

export default RichText;
