import React from 'react';
import FadeIn from '../FadeIn';
import ProjectTile from '../ProjectTile';

export default function ProjectTileBlock({ rel, block, project }) {
	return (
		<FadeIn>
			<ProjectTile project={block.project} rel={block} />
		</FadeIn>
	);
}
