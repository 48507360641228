import React from 'react';
import { PortableText } from '@portabletext/react';
import FadeIn from '../FadeIn';

const TextWithHeading = ({ block, raw, index }) => (
	<div
		className={`col-span-${block.settings?.mobileWidth || 12} lg:col-span-${
			block.settings?.desktopWidth || 12
		}`}
	>
		<FadeIn>
			<div className="my-4 lg:my-8 border-t border-black py-2 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-8 ">
				<div className="w-64">
					<h2 className="h3">{block.heading}</h2>
				</div>
				<div className="prose flex-1">
					<PortableText value={raw.text} />
				</div>
			</div>
		</FadeIn>
	</div>
);

export default TextWithHeading;
