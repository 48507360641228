import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function FadeIn(props) {
	const el = useRef();
	useLayoutEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		gsap.set(el.current, {
			autoAlpha: 0,
		});
		gsap.to(el.current, {
			autoAlpha: 1,
			ease: 'power4.out',
			duration: 2,
			scrollTrigger: {
				//markers: true,
				trigger: el.current,
				start: 'top 85%',
				end: 'bottom top',
				stagger: 0.5,
			},
		});
	});
	return <div ref={el}>{props.children}</div>;
}
