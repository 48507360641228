import React, { useState } from 'react';

import { Link, useStaticQuery, graphql } from 'gatsby';
import SanityLink from './SanityLink';
import logo from '../images/logo.svg';
import Noise from './Noise';

export default function Header(props) {
	const data = useStaticQuery(graphql`
		query NavQuery {
			header: sanitySettingsHeader {
				menu {
					items {
						...navItems
					}
				}
			}
		}
	`);

	const nav = data.header.menu.items;

	const [state, setState] = useState('');

	const toggleMenu = () => {
		setState(state === '' ? 'is-open' : '');
		document.body.classList.toggle('overflow-hidden');
	};
	const closeMenu = () => {
		setState(state === 'is-open' ? '' : '');
		document.body.classList.remove('overflow-hidden');
	};
	return (
		<>
			<header className={`navbar`}>
				<Noise />
				<button
					onClick={toggleMenu}
					className={`menu-trigger ${state}`}
				>
					<span></span>
					<span></span>
					<span></span>
				</button>
				<Link to="/" className={`navbar__logo`} onClick={closeMenu}>
					<img src={logo} alt="Ebb & Flow Studio" />
				</Link>
			</header>
			<div className={`menu ${state}`}>
				<Noise />
				<nav className="w-full">
					<ul>
						{nav.map((link) => (
							<li key={link._key}>
								<SanityLink
									link={link.navigationItemUrl}
									onClick={closeMenu}
								>
									{link.text}
								</SanityLink>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</>
	);
}
