import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import FadeIn from '../FadeIn';

const ImageBlock = ({ block, raw, index }) => (
	<div
		className={`col-span-${block.settings?.mobileWidth || 12} lg:col-span-${
			block.settings?.desktopWidth || 12
		}`}
	>
		<FadeIn>
			<GatsbyImage
				image={block.asset.gatsbyImageData}
				alt={block.alt}
				className="w-full"
			/>
		</FadeIn>
	</div>
);

export default ImageBlock;
